// LandingPage.vue
<template>
  <v-container class="fill-height ">
    <v-row align-content="center">

      <v-col cols="12" md="12" class="text-center" >
      
                    <v-avatar size="145px"   key="welcome" class="glass">
                      <v-img width="100%" class="mx-auto" src="Tomas_Pereiro_entrenador.png" alt="Presentacion_entrenador" />
                    </v-avatar>

                  <h1 class="mt-4 text-h4">
                    Tomas Pereiro 


                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn  class="mx-auto glass"   fab small v-on="on"
                href="https://wa.me/+59891272285?text=Hola!%20me%20interesa%20mejorar%20mi%20salud"
                target="_blank">
                <v-icon >
                  mdi-whatsapp
                </v-icon>
                
              </v-btn>
                      </template>
                      <span>Envíame un mensaje de WhatsApp</span>
                    </v-tooltip>

                   
                  </h1>
       
      </v-col>

      <v-col cols="12" md="12">

          <v-list rounded  max-width="330" class="mx-auto glass py-0">

            <v-list-item class="my-2">
              <v-list-item-avatar size="30" color="success">
                <v-icon dark >mdi-check</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Entrenamientos personalizados</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider/>

            <v-list-item class="my-2">
              <v-list-item-avatar size="30" color="success">
                <v-icon dark>mdi-check</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Seguimiento de progreso</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider/>
            <v-list-item class="my-2">
              <v-list-item-avatar size="30" color="success">
                <v-icon dark>mdi-check</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Entrenamientos en video</v-list-item-title>
              </v-list-item-content>
            </v-list-item>




          </v-list>
         <div key="buttons1" class="text-center">


            <v-btn class="mt-4 rounded-lg mx-auto glass" large  v-if="showInstallButton" @click="overlayAddToHomeScreen=true" rounded>
              <v-icon left>mdi-plus</v-icon>
              Agregar a inicio
            </v-btn>
            <br v-if="showInstallButton"/>

            <v-btn class="mt-4  rounded-lg glass" large  v-if="showInstallPrompt" @click="installPWA" rounded>
              <v-icon left>mdi-download</v-icon>
              Instalar aplicación
            </v-btn>

            <br v-if="showInstallPrompt"/>

            <!--   <v-btn large dark color="#2196f3" class="mr-5" @click="$router.replace('/signup')">
              
              <v-icon left>mdi-account-plus</v-icon>Comezar 
          </v-btn> -->


            <v-btn x-large  @click="$router.push('/login')" color="primary" rounded class="rounded-lg mx-auto mt-4 glass">
              <v-icon left>
                mdi-login
              </v-icon>
              Iniciar sesion</v-btn>

          </div>
      </v-col>
    </v-row>


    <v-overlay v-model="overlayAddToHomeScreen" color="black" opacity="0.8">
      <v-card class="mx-auto" max-width="300" >
        <v-card-title class="text-h5"
        >Agregar a inicio
          <v-spacer></v-spacer>

          <v-icon @click="overlayAddToHomeScreen = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider/>
        <v-card-text>

            <p>Para agregar esta aplicación a la pantalla de inicio de su dispositivo iOS, siga los siguientes pasos:</p>
          
            <ol>
              <li>Abra la aplicación en 
                
                 Safari en su dispositivo iOS.</li>
              <li>Toque el icono de compartir 
                <v-icon>
                  mdi-export-variant
                </v-icon> 
              </li>
              <li>Toque "Agregar a inicio" 
                <v-icon>
                  mdi-plus-box-outline
                </v-icon>
              </li>
              <li>
                Y listo! Busque el icono en su pantalla de inicio.

              </li>
            </ol>



        </v-card-text>
                <v-divider/>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary"  @click="overlayAddToHomeScreen = false">Cerrar</v-btn>
          <v-spacer></v-spacer>

        </v-card-actions>
      </v-card>
    </v-overlay>
  </v-container>
</template>

<script>
export default {
  name: "LandingPage",
  data() {
    return {
      overlayAddToHomeScreen: false,
      showInstallPrompt: false,
      deferredPrompt: null,
    }
  },
  methods: {
    async installPWA() {
      if (this.deferredPrompt) {
        this.showInstallPrompt = false;
        this.deferredPrompt.prompt();
        const { outcome } = await this.deferredPrompt.userChoice;
        //console.log(`User response to the install prompt: ${outcome}`);
        this.deferredPrompt = null;
      }
    },
  },
  mounted() {
    this.$vuetify.goTo(0);
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      this.showInstallPrompt = true;
    });
  },
  computed: {
    isIOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
    isStandalone() {
      return (window.navigator.standalone !== undefined) ? window.navigator.standalone : false;
    },
    showInstallButton() {
      return this.isIOS && !this.isStandalone;
    }
  },
};
</script>

<style scoped>
@media screen and (max-width: 600px) {
  .imgLanding {
    width: 100%;
  }
}

@media screen and (min-width: 600px) {
  .imgLanding {
    width: 500px;
  }
}
</style>